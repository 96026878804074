import Navigation from "./routes/navigation";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";
import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

require("dotenv").config();

function App() {
  return (
    <>
      <div>
        <ApolloProvider client={client}>
          <Navigation />
          <FloatingWhatsApp
            phone="527531288040"
            showPopup={false}
            popupMessage="Hola, ¿Cómo podemos ayudarte?"
            headerTitle="Rock and RO Chat"
            
          />
        </ApolloProvider>
      </div>
    </>
  );
}

export default App;
