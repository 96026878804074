import React from "react";
import Swal from "sweetalert2";

const Alerta = (props) => {
  const { title, text, icon } = props;
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: "Aceptar",
  });
};

export default Alerta;
