import React from "react";
import Header from "../components/Breadcrumb";
const Events = () => {
  return (
    <>
      <Header pageTitle="Eventos" />
      <section className="about-section section-gap">
        <div className="container">
          <div
            class="calendly-inline-widget"
            data-url="https://calendly.com/fjgmtz?hide_landing_page_details=1"
            Style={""}
          ></div>
          <script
            type="text/javascript"
            src="https://assets.calendly.com/assets/external/widget.js"
            async
          ></script>
        </div>
      </section>
    </>
  );
};

export default Events;
