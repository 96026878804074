import React from "react";
import { Button, Form, Card, Col, Row } from "react-bootstrap";
import { CREATED_ACCOUNT } from "../../../gql/Account.gql";
import { useMutation } from "@apollo/client";
import Alerta from "../../Alerta/Alerta";
import { useFormik } from "formik";
import * as Yup from "yup";

const FrmRegistrarCuenta = () => {
  //Mutations
  const [addAccount] = useMutation(CREATED_ACCOUNT);
  //Formik implementation
  const formik = useFormik({
    initialValues: {
      userid: "",
      user_pass: "",
      repeat_user_pass: "",
      email: "",
      sex: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string()
        .required("Ingresa el usuario de tu cuenta")
        .min(4, "El nombre de usuario debe ser mayor o igual 4 carácteres."),
      user_pass: Yup.string()
        .required("La contraseña es requerida")
        .min(4, "La contraseña debe ser mayor o igual 4 carácteres."),
      email: Yup.string()
        .required("El correo electrónico es requerido")
        .email(true),
      sex: Yup.string().required("Debes seccionar un genero para tu cuenta"),
    }),
    onSubmit: async (input) => {
      try {
        const { userid, user_pass, email, sex } = input;
        if (sex === "") throw new Error("error");
        const { data } = await addAccount({
          variables: {
            input: { userid, user_pass, sex, email },
          },
        });
        Alerta({ text: data.addAccount, title: "Éxito", icon: "success" });
      } catch (error) {
        Alerta({ text: error.message, title: "Error", icon: "error" });
      }
    },
  });
  return (
    <>
      <Card style={{ opacity: "0.8" }}>
        <Card.Header>Crear una cuenta</Card.Header>
        <Card.Body>
          <Form
            className="form-area contact-form"
            onSubmit={formik.handleSubmit}
          >
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Usuario
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Usuario"
                  className="common-input mb-20 form-control"
                  id="userid"
                  value={formik.values.userid}
                  onChange={formik.handleChange}
                />
                <p className="text-danger">{formik.errors.userid}</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Contraseña
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  id="user_pass"
                  className="common-input mb-20 form-control"
                  value={formik.values.user_pass}
                  onChange={formik.handleChange}
                />
                <p className="text-danger">{formik.errors.user_pass}</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Repetir contraseña
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="password"
                  name="repeat_user_pass"
                  placeholder="Repetir contraseña"
                  className="common-input mb-20 form-control"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Correo electrónico
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Correo electrónico"
                  className="common-input mb-20 form-control"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <p className="text-danger">{formik.errors.email}</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Sexo:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  id="sex"
                  defaultValue="Selecciona..."
                  value={formik.values.sex}
                  onChange={formik.handleChange}
                >
                  <option value="">Seleccione...</option>
                  <option value="m">Hombre</option>
                  <option value="f">Mujer</option>
                </Form.Control>
                <p className="text-danger">{formik.errors.sex}</p>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Button onClick={formik.handleSubmit}>Crear cuenta</Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default FrmRegistrarCuenta;
