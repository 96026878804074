import { gql } from "@apollo/client";

export const GET_CONFIG = gql`
  query getDefaultConfig {
    getDefaultConfig {
      id
      siteName
      direccion
      logo
    }
  }
`;

export const GET_DESCARGAS = gql`
  query getDescargas {
    getDescargas {
      id
      nombre
      descripcion
      url
      tipo
      servidor
    }
  }
`;
