import React from "react";
import Header from "../../../components/Breadcrumb";
import FrmPasswordRecovery from "../../../components/Formularios/FrmPasswordRecovery";
const PasswordRecovery = () => {
  return (
    <>
      <Header pageTitle="Recuperar contraseña" />
      <section className="about-section section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h2>
                Recuperar contraseña
                <br />
              </h2>
              <p className="pt-20 pb-20">
                Por favor ingresa el correo electrónico con el que registraste
                tu cuenta.
              </p>
              <FrmPasswordRecovery />
            </div>
            <div className="col-lg-6 text-center">
              <img className="img-fluid" src="/img/rockandro/ws.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordRecovery;
