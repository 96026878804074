import Hero from "../components/Hero";
import Sorry from "../components/Sorry/Sorry";

const Home = () => {
  return (
    <>
      <Hero />
      <Sorry />
    </>
  );
};

export default Home;
