import { gql } from "@apollo/client";

export const CREATED_ACCOUNT = gql`
  mutation CREATED_ACCOUNT($input: InputAccount) {
    addAccount(input: $input)
  }
`;

export const RECOVERY_PASSWORD = gql`
  mutation RECOVERY_PASSWORD($input: InputRecovery) {
    recoveryPassword(input: $input)
  }
`;
