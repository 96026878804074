//Capas

import Donaciones from "../components/About/Donaciones/Donaciones";
import Layout from "../Layout/Base";
import ContenidoPage from "../Layout/Contenido";
//Paginas
import About from "../pages/About";
import Events from "../pages/Events";
import Galerias from "../pages/Galerias";
import PasswordRecovery from "../pages/Help/PasswordRecovery/";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Ranking from "../pages/Ranking";

const routes = [
  {
    path: "/",
    layout: Layout,
    component: Home,
    exact: true,
  },
  {
    path: "/nosotros",
    layout: ContenidoPage,
    component: About,
    exact: true,
  },
  {
    path: "/informacion/donaciones",
    layout: ContenidoPage,
    component: Donaciones,
    exact: true,
  },
  {
    path: "/descargas",
    layout: ContenidoPage,
    component: Galerias,
    exact: true,
  },
  {
    path: "/eventos",
    layout: ContenidoPage,
    component: Events,
    exact: true,
  },
  {
    path: "/login",
    layout: ContenidoPage,
    component: Login,
  },
  {
    path: "/ranking",
    layout: ContenidoPage,
    component: Ranking,
  },
  {
    path: "/password-recovery",
    layout: ContenidoPage,
    component: PasswordRecovery,
  },
];

export default routes;
