import React from "react";
import Header from "../components/Breadcrumb";
const About = () => {
  return (
    <>
      <Header pageTitle="NOSOTROS" />
      <section className="about-section section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h1>
                😸 Nuestro compromiso
                <br />
              </h1>
              <p className="pt-20 pb-20">
                Mantenemos el juego lo más apegado a un estilo clásico sin
                descuidar las actualizaciones "Renewal", queremos ofrecerte un
                servidor serio y profesional. Por ello invertimos en
                infraestructura que nos permita reducir al máximo la perdida de
                datos. Respaldamos tu información diariamente para evitar tener
                que hacer Rollback o Resets asegurandonos que las copias
                funcionen.
              </p>
              <p className="pt-20 pb-20">
                <strong>Nuestro compromiso</strong> es mantener operativo el
                servidor hasta que este marque "cero jugadores" por 30 días
                consecutivo, esto significa que así sea (1) jugador "jugando" el
                servidor permanecerá activo.
              </p>
            </div>
            <div className="col-lg-6 text-center">
              <img
                className="img-fluid"
                src="/img/rockandro/wizzard.png"
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h1>😸 Información Básica</h1>
              <ul>
                <li> ✨ Rates 132x Base Exp/132x Job Exp/Drop Variable </li>
                <li> ✨ Base 255 / Job 120</li>
                <li> ✨ 195 ASPD </li>
                <li> ✨ 150 Dex For Free Cast</li>
                <li>
                  ✨ Equipo custom (4) Slots con estilo de combate elemental
                </li>
                <li> ✨ 0.10 MVP / Mini Boss Card Rates / 1 Normal Card</li>
              </ul>
            </div>
            <div className="col-lg-6 about-left"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
