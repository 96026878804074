import React from "react";
import { Spinner, Table } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_DESCARGAS } from "../../../gql/Config.gql";
const TablaParche = () => {
  //Graphql
  const { data, loading, error } = useQuery(GET_DESCARGAS);
  if (loading)
    return (
      <>
        <Spinner animation="grow" />
        <span className="visually-hidden">Cargando datos...</span>
      </>
    );
  if (error) return <p>No es posible procesar la solicitud en este momento</p>;

  const { getDescargas } = data;
  let decargas = getDescargas.filter((d) => d.tipo === 2);
  return (
    <Table striped hover>
      <thead>
        <tr>
          <th>Mirror</th>
          <th>Enlace</th>
          <th>Fecha de actualización</th>
        </tr>
      </thead>
      <tbody>
        {decargas.map((d) => (
          <tr>
            <td>{d.servidor}</td>
            <td>
              <a href={`${d.url}`} target="_blank" rel="noreferrer">
                Descargar
              </a>
            </td>
            <td></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TablaParche;
