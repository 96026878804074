import React from "react";
import Alquimia from "../components/Ranks/Alquimia/";
import Breadcrumb from "../components/Breadcrumb";
const Ranking = () => {
  return (
    <>
      <Breadcrumb pageTitle="RANKINGS" />
      <section className="about-section section-gap">
        <Alquimia />
      </section>
    </>
  );
};

export default Ranking;
