import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Alerta from "../../Alerta/Alerta";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { RECOVERY_PASSWORD } from "../../../gql/Account.gql";

const FrmPasswordRecovery = () => {
  //Mutations
  const [recoveryPassword] = useMutation(RECOVERY_PASSWORD);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("El correo electrónico es requerido")
        .email("Formato no valido"),
    }),
    onSubmit: async (input) => {
      try {
        const { email } = input;
        const { data } = await recoveryPassword({
          variables: {
            input: { email },
          },
        });
        Alerta({
          text: data.recoveryPassword,
          title: "Éxito",
          icon: "success",
        });
      } catch (error) {
        Alerta({ text: error.message, title: "Error", icon: "error" });
      }
    },
  });
  return (
    <>
      <Card style={{ opacity: "0.8" }}>
        <Card.Body>
          <Form
            onSubmit={formik.handleSubmit}
            className="form-area contact-form"
          >
            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                <strong>Correo Electrónico</strong>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="mail"
                  placeholder="Correo Electrónico"
                  className="common-input mb-20 form-control"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <p className="text-danger">{formik.errors.email}</p>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Button onClick={formik.handleSubmit}>Recuperar</Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default FrmPasswordRecovery;
