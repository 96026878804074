import React from "react";
import Breadcrum from "../../Breadcrumb";
const Donaciones = () => {
  return (
    <>
      <Breadcrum pageTitle="DONACIONES" />
      <section className="about-section section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h2>😸 Información donaciones</h2>
              <p className="pt-20 pb-20">
                Gracias por apoyar a que este proyecto continue activo, si bien
                parte de la infraestructura brindada es compartida, la
                aportaciones realizadas nos permiten adquirir nuevos elementos
                para mejorar el servidor
              </p>
              <p className="pt-20 pb-20">
                Recuerda que puedes consultar con un G.M el monto del ítem que
                deseas adquirir para tu personaje.
              </p>
              <p>
                <strong>Importante: </strong> Cuentas con 7 días para evaluar el
                ítem y pedir un cambio, pasando este periodo de tiempo ya no es
                posible realizarte el cambio.
              </p>
            </div>
            <div className="col-lg-6 text-center">
              <img
                className="img-fluid"
                src="/img/rockandro/supernovice1.png"
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h2>😸 Donativos desde México</h2>
              <p>
                <ul>
                  <li>
                    <strong>Banco:</strong> Banorte
                  </li>
                  <li>
                    <strong> No. de Cuenta:</strong> 1162485261
                  </li>
                  <li>
                    <strong>Clabe:</strong> 072497011624852617
                  </li>
                  <li>
                    <strong>Titular:</strong> Francisco Javier Guerrero Martínez
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 about-left">
              <p>
                Para un donativo en OXXO deberás seguir el siguiente
                procedimiento:
              </p>
              <ol>
                <li>
                  Solicitar al cajero un pago de servicio <b>Albo</b>
                </li>
                <li>Dictar el siguiente No. de Referencia: 07097937</li>
              </ol>
            </div>
          </div>
          <p>
            Si deseas realizar tu donativo desde otro país por el momento solo
            utilizamos Paypal <i>javier@aurorati.mx</i> Esperamos en los
            próximos días poder habilitar una nueva pasarela de pagos
          </p>
        </div>
      </section>
    </>
  );
};

export default Donaciones;
