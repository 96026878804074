import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const Sorry = () => {
  return (
    <section className="execution-secation section-gap aquablue-bg">
      <div className="container">
        <div className="row justify-content-center section-title-wrap">
          <div className="col-lg-12">
            <h1 style={{ color: "white" }}>🛠️ WE SORRY 🛠️</h1>
          </div>
        </div>
        <Card style={{ opacity: "0.8" }}>
          <Card.Text>
            <div className="row align-items-center justify-content-between">
              {/* <div className="col-lg-6 text-center">
               
              </div> */}
              <div className="col-lg-12 ">
                <p></p>
                <p>
                  Estimado visitante / jugador por el momento nuestro sitio web
                  se encuentra en mantenimiento ⚙️ puedes elegir alguna de las
                  siguientes opciones si deseas:{" "}
                </p>
                <ul>
                  <li>
                    {" "}
                    ✔️{" "}
                    <a
                      href="https://panel.rockandro.net"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Crear una nueva cuenta o gestionar tu cuenta utiliza el
                      panel de control.{" "}
                    </a>
                  </li>
                  <li>
                    {" "}
                    ✔️{" "}
                    <a
                      href="https://old.rockandro.net/ranking/woe"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Consultar el Ranking de WoE{" "}
                    </a>{" "}
                  </li>
                  <li>
                    ✔️{" "}
                    <a
                      href="https://old.rockandro.net/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Votar por Rock and RO
                    </a>
                  </li>
                </ul>
                <p>
                  Recuerda que estos cambios son parte del proyecto de
                  renovación que llevamos tiempo desarrollando, te pedimos
                  disculpa. El staff de Rock and Ro
                </p>
              </div>
            </div>
          </Card.Text>
        </Card>
      </div>
    </section>
  );
};

export default Sorry;
