import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
const Footer = (props) => {
  const { direccion } = props;
  return (
    <>
      <footer className="footer-area section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="single-footer-widget">
                <h4></h4>
                <p></p>
              </div>
            </div>

            <div className="col-lg-2 col-md-6"></div>
            <div className="col-lg-2 col-md-6">
              <div className="single-footer-widget">
                <h4>Siguenos</h4>
                <ul className="social-icons">
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h4>Boletin</h4>
              </div>
            </div>
          </div>
          <div className="footer-bottom row justify-content-center mt-70">
            <p className="footer-text m-0 col-lg-6 col-md-12">
              Copyright © 2021 Derechos reservados, sitio desarrollado por &nbsp;
              <a href="https://github.com/DiWolf">
                <strong>DiWolf</strong>
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
