import React from "react";
import Header from "../components/Breadcrumb";
import TablaCompleto from "../components/Descargas/Completo/TablaCompleto";
import TablaParche from "../components/Descargas/Parcher/TablaParche";
const Galerias = () => {
  return (
    <>
      <Header pageTitle="DESCARGAS" />
      <section className="about-section section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <h2>
                😸 Descargas (Juego Completo)
                <br />
              </h2>
              <p className="pt-20 pb-20">
                A continuación encontrarás un listado de sitios de donde puedes
                descargar el juego completo.
              </p>
              <TablaCompleto />
            </div>
            <div className="col-lg-6 text-center">
              <img className="img-fluid" src="/img/rockandro/ws.png" alt="" />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 text-center">
              <img
                className="img-fluid"
                src="/img/rockandro/snmitad.png"
                alt=""
              />
            </div>
            <div className="col-lg-6 about-left">
              <h2>
                😸 Descargas (Parche del Servidor)
                <br />
              </h2>
              <p className="pt-20 pb-20">
                A continuación encontrarás un listado de sitios de donde puedes
                descargar el parche del juego
              </p>
              <TablaParche />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Galerias;
