import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const Menu = () => {
  //States iniciales
  const [showHomeMenu, setshowHomeMenu] = useState(false);
  const [showRankingMenu, setshowRankingMenu] = useState(false);
  const [showMenuAyuda, setShowMenuAyuda] = useState(false);
  const [openMobileMenu, setopenMobileMenu] = useState(false);
  //Mobile menu
  const ShowMobileMenu = () => {
    alert("x");
  };

  //Toogle menu
  const homeMenuToggle = (e) => {
    const currenState = showHomeMenu;
    setshowHomeMenu(!currenState);
    setshowRankingMenu(false);
    setShowMenuAyuda(false);
    e.preventDefault();
  };

  const homeMenuMouseLeave = (e) => {
    e.preventDefault();
    setTimeout(function () {
      setshowHomeMenu(false);
    }, 800);
  };
  const homeMenuMouseEnter = (e) => {
    e.preventDefault();
    setshowHomeMenu(true);
  };

  //Rankins
  const rankingMenuToggle = (e) => {
    const currenState = showRankingMenu;
    setshowRankingMenu(!currenState);
    setShowMenuAyuda(false);
    setshowHomeMenu(false);
    e.preventDefault();
  };

  const rankingMenuMouseLeave = (e) => {
    e.preventDefault();
    setTimeout(function () {
      setshowRankingMenu(false);
    }, 800);
  };
  const rankingMenuMouseEnter = (e) => {
    e.preventDefault();
    setshowRankingMenu(true);
  };

  //Ayuda menu
  const ayudaMenuToggle = (e) => {
    const currenState = showMenuAyuda;
    setShowMenuAyuda(!currenState);
    setshowHomeMenu(false);
    setshowRankingMenu(false);
    e.preventDefault();
  };

  const ayudaMenuMouseLeave = (e) => {
    e.preventDefault();
    setTimeout(function () {
      setShowMenuAyuda(false);
    }, 800);
  };
  const ayudaMenuMouseEnter = (e) => {
    e.preventDefault();
    setShowMenuAyuda(true);
  };

  return (
    <>
      <Button
        type="button"
        id="mobile-nav-toggle"
        onClick={() => {
          ShowMobileMenu();
        }}
      >
        <i className="lnr lnr-menu"></i>
      </Button>
      <div id="mobile-body-overly"></div>
      <nav id="nav-menu-container">
        <ul
          className="parent-active nav-menu nav-menu2"
          style={{ touchAction: "pan-y" }}
        >
          <li>
            <Link to={"/"}>Inicio</Link>
          </li>
          <li>
            <Link
              to={"/nosotros"}
              onClick={(e) => {
                homeMenuToggle(e);
              }}
              className="sf-with-ul"
            >
              Información
            </Link>
            <ul
              style={showHomeMenu ? { display: "block" } : { display: "none" }}
              onMouseLeave={() => homeMenuMouseLeave}
              onMouseEnter={() => homeMenuMouseEnter}
            >
              <li>
                {" "}
                <Link to={"/nosotros"}> Acerca de Rock and RO</Link>
              </li>
              <li>
                {" "}
                <Link to={"/informacion/donaciones"}>
                  {" "}
                  Información sobre donaciones{" "}
                </Link>{" "}
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/descargas"}>Descargas</Link>
          </li>
          <li>
            <Link
              to={"/ranking"}
              onClick={(e) => {
                rankingMenuToggle(e);
              }}
              className="sf-with-ul"
            >
              Ranks
            </Link>
            <ul
              style={
                showRankingMenu ? { display: "block" } : { display: "none" }
              }
              onMouseLeave={() => rankingMenuMouseLeave}
              onMouseEnter={() => rankingMenuMouseEnter}
            >
              <li>
                {" "}
                <Link to={"/ranking"}> Alchemist</Link>
              </li>
              <li>
                {" "}
                <Link to={"/informacion/donaciones"}> Taekwond </Link>{" "}
              </li>
            </ul>
          </li>
          <li>Blog</li>
          <li>
            <Link
              to={"/"}
              onClick={(e) => {
                ayudaMenuToggle(e);
              }}
              className="sf-with-ul"
            >
              Ayuda
            </Link>
            <ul
              style={showMenuAyuda ? { display: "block" } : { display: "none" }}
              onMouseLeave={() => ayudaMenuMouseLeave}
              onMouseEnter={() => ayudaMenuMouseEnter}
            >
              <li>
                {" "}
                <Link to={"/password-recovery"}>RECUPERAR CONTRASEÑA</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Menu;
