import { gql } from "@apollo/client";

export const GET_ALQUIMIA = gql`
  query getAlchimist {
    getAlchimist {
      name
      fame
    }
  }
`;
