import React from "react";
import Slider from "react-slick";
import FrmRegistrarCuenta from "../Formularios/FrmRegistrarCuenta";
const Hero = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const opts = {
    playerVars: {
      autoplay: true,
    },
  };
  return (
    <>
      <section
        className="hero-section-bg relative "
        style={{
          overflow: "hidden",
          background: "linear-gradient(rgba(0,0,0,1), rgba(2,0,238,0.77))",
        }}
      >
        <div id="sass-owl" className="owl-carousel owl-theme ">
          <Slider {...settings}>
            <div className="items">
              <img
                className="owl-img9 "
                width="100%"
                alt=""
                src="/img/rockandro/hero1.jpg"
                style={{ opacity: "0.5 " }}
              />
            </div>
          </Slider>
        </div>
        <div className="content">
          <div className="container">
            <div className="row align-items-center relative">
              <div className="col-lg-6">
                <h1>¡Bienvenido Rock and RO!</h1>
                <p className="pt-20 pb-20 mw-510">
                  Rock and RO es un juego en línea basado en el famoso MMORPG
                  Ragnarok OnLine agregando un toque original de jugabilidad,
                  utiliza los diez elementos para derrocar tus enemigos, diseña
                  la estrategia que te llevará a ti y a tu equipo a la victoria.
                  Con más de 1000 Items customs y 200 quest. Buscas un servidor
                  estable con más 3 años de tres años de experiencia sin
                  rollback y un equipo comprometido ¡Este es tu lugar!
                </p>
                {/* <Link to="/index" className="genric-btn2">
                  Browse free demo
                </Link> */}
              </div>
              <div className="col-lg-6">
                {/* <Youtube videoId="ttQgSXlGEok" opts={opts} /> */}
                {/* <img className="hero-img9" src={heroImg} alt="" /> */}
                <FrmRegistrarCuenta />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
