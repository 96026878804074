import React from "react";

const Breadcrumb = (props) => {
  const { pageTitle } = props;
  return (
    <>
      <section className="page-top-section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-md-6">
              <h1 className="text-white">{pageTitle}</h1>
            </div>
            <div className="col-lg-6  col-md-6 page-top-nav">
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
