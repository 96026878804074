import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Alerta from "../../Alerta/Alerta";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
const FrmLogin = () => {
  const formik = useFormik({
    initialValues: {
      userid: "",
      user_pass: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string().required("El nombre de usuario es requerido"),
      user_pass: Yup.string().required("La contraseña es requerida"),
    }),
    onSubmit: async (input) => {
      console.log(input);
    },
  });
  return (
    <Form className="form-area contact-form" onSubmit={formik.handleSubmit}>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          <strong>Usuario</strong>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="userid"
            placeholder="Usuario"
            className="common-input mb-20 form-control"
            id="userid"
            value={formik.values.userid}
            onChange={formik.handleChange}
          />
          <p className="text-danger">{formik.errors.userid}</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          <strong>Contraseña</strong>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="password"
            placeholder="Contraseña"
            className="common-input mb-20 form-control"
            id="user_pass"
            value={formik.values.user_pass}
            onChange={formik.handleChange}
          />
          <p className="text-danger">{formik.errors.user_pass}</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm="4">
          <Button onClick={formik.handleSubmit}>Ingresar</Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default FrmLogin;
