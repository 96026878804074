import React from "react";
import Header from "../components/Breadcrumb";
import FrmLogin from "../components/Formularios/FrmLogin/";
const Login = () => {
  return (
    <>
      <Header pageTitle="Ingresar" />
      <section className="about-section section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 about-left">
              <img
                className="img-fluid"
                src="/img/rockandro/kodama.png"
                alt="Login"
              />
            </div>
            <div className="col-lg-6 text-center">
              <h2>
                Iniciar sesión
                <br />
              </h2>
              <p className="pt-20 pb-20 text-justify">
                Ingresa para administrar tu cuenta, votar por Rock and RO, abrir
                tickets de soporte, reportar errores, etc.
              </p>
              <FrmLogin />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
