import { useLazyQuery, useQuery } from "@apollo/client";
import React from "react";
import { GET_ALQUIMIA } from "../../../gql/Ranking.gql";
const Alquimia = () => {
  //Graphql
  const { data, loading, error } = useQuery(GET_ALQUIMIA);
  if (loading) return <h1>cargando</h1>;
  if (error)
    return <h1>No es posible procesar la solicitud en este momento</h1>;
  const { getAlchimist } = data;
  return <h1>{getAlchimist[0].name}</h1>;
};

export default Alquimia;
